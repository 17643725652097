#intro {
    height: calc(100vh - 4rem);
    width: 100vw;
    max-width: 75rem;
    margin: 0 auto;
    overflow: hidden;
    position: relative;
}

.bg {
    position: absolute;
    top: 0;
    right: 0;
    z-index: -1;
    object-fit: contain;
    height: 100vh;
}

.introContent {
    height: 100vh;
    width: 100vw;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.introName {
    font-size: 3rem;
    font-weight: 700;
    color: #4a81cf;
}

.titleText {
    font-size: 2rem;
    font-weight: 500;
}

.introPara {
    font-size: medium;
    font-weight: 300;
    letter-spacing: 1px;
}

.line {
    position: relative;
    overflow: hidden;
    display: flex;
}

.yellowBtn {
    background: white;
    margin: 1rem 0;
    padding: 0.75rem 1.5rem;
    border: none;
    border-radius: 2rem;
    width: 10rem;
}

.yellowBtn:visited {
    color: black;
}

.yellowBtn:hover {
    background: #4a81cf;
}

@media screen and (max-width:840px) {
    .bg {
        right: -10vw
    }

    .introContent {
        font-size: 5vw;
    }
    
}

@media screen and (max-width:480px) {
    .bg {
        right: -20vw
    }
}