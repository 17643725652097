#presentations {
    margin: 0 auto;
    min-height: calc(100vh - 4rem);
    text-align: center;
    padding: 0 1rem;
}

.presentationCard {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 2em !important;
    background: rgb(50, 50, 50);
    border-radius: 0.5rem;
    color: white;
    height: 100%;
}

.presentation-col-expand {
    flex: 0 0 auto;
    width: 33.33333333%;
}

@media screen and (max-width:1000px) {
    .presentation-col-expand {
        flex: 0 0 auto;
        width: 100%;
    }
}

.presentationBtn {
    align-self: center;
    margin: 1rem 0;
    padding: 0.75rem 1.5rem;
    border: 2px solid #4a81cf;
    border-radius: 1rem;
    width: 10rem;
    color: white !important;
}

.presentationBtn:hover {
    background: #4a81cf;
    color: black;
}